
import loader from "../assets/img/icons/loader.gif"


export function Loarder({ }) {

    return (
        <div className="board-loader-container">
            <img src={loader} />
            <span>Trellax</span>
        </div>
    )
}
